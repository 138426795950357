// src/components/layout/Navbar.js
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import UserTypeDisplay from '../shared/UserTypeDisplay';
import { 
  ChevronDown, 
  User, 
  LogOut, 
  Settings, 
  Heart, 
  MessageSquare, 
  Bell, 
  PlusCircle,
  History,
  Building2,
  Bookmark
} from 'lucide-react';

const Navbar = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleLogout = () => {
    logout();
    navigate('/');
    setIsDropdownOpen(false);
  };

  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16 items-center">
          <Link to="/" className="text-xl font-bold text-gray-800">
            <img
              src="/images/ventaterrenocl.png"
              alt="VentaTerreno.cl"
              className="h-8 w-auto object-contain"
            />
          </Link>
          <div className="flex space-x-4 items-center">
            {/* 
            <Link to="/" className="text-gray-600 hover:text-gray-900">
              Inicio
            </Link>
            */}
            <Link to="/contacto" className="text-gray-600 hover:text-gray-900">
              Contacto
            </Link>
            
            {user ? (
              <div className="relative" ref={dropdownRef}>
                <div className="flex items-center space-x-3">
                  {/* Botón de publicar */}
                  <Link
                    to="/publicar"
                    className="flex items-center space-x-2 text-blue-600 hover:text-blue-700"
                  >
                    <PlusCircle className="h-5 w-5" />
                    <span className="hidden md:inline">Publicar</span>
                  </Link>

                  {/* Ícono de notificaciones */}
                  <button className="text-gray-600 hover:text-gray-900 relative">
                    <Bell className="h-5 w-5" />
                    <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                      2
                    </span>
                  </button>

                  {/* Menú de usuario */}
                  <button
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    className="flex items-center space-x-2 text-gray-600 hover:text-gray-900 focus:outline-none"
                  >
                    <span className="font-medium">{user.nombre}</span>
                    <ChevronDown 
                      className={`h-4 w-4 transition-transform duration-200 ${
                        isDropdownOpen ? 'transform rotate-180' : ''
                      }`}
                    />
                  </button>
                </div>

                {/* Menú desplegable */}
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 py-2 w-56 bg-white rounded-md shadow-xl z-50 border border-gray-100">
                    {/* Perfil de Usuario */}
                    <div className="px-4 py-2 text-sm text-gray-500 border-b border-gray-100">
                      <div className="font-medium text-gray-800">{user.nombre}</div>
                      <div className="text-xs">{user.email}</div>
                      <UserTypeDisplay userType={user.tipo} />
                    </div>

                    {/* Gestión de Propiedades */}
                    <div className="py-1 border-b border-gray-100">
                      <Link
                        to="/mis-propiedades"
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => setIsDropdownOpen(false)}
                      >
                        <Building2 className="h-4 w-4 mr-2" />
                        Mis Propiedades
                      </Link>
                      <Link
                        to="/publicar"
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => setIsDropdownOpen(false)}
                      >
                        <PlusCircle className="h-4 w-4 mr-2" />
                        Publicar Propiedad
                      </Link>
                    </div>

                    {/* Actividad y Seguimiento */}
                    <div className="py-1 border-b border-gray-100">
                      <Link
                        to="/favoritos"
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => setIsDropdownOpen(false)}
                      >
                        <Heart className="h-4 w-4 mr-2" />
                        Favoritos
                      </Link>
                      <Link
                        to="/guardados"
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => setIsDropdownOpen(false)}
                      >
                        <Bookmark className="h-4 w-4 mr-2" />
                        Propiedades Guardadas
                      </Link>
                      <Link
                        to="/historial"
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => setIsDropdownOpen(false)}
                      >
                        <History className="h-4 w-4 mr-2" />
                        Historial de Visitas
                      </Link>
                    </div>

                    {/* Comunicaciones */}
                    <div className="py-1 border-b border-gray-100">
                      <Link
                        to="/mensajes"
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => setIsDropdownOpen(false)}
                      >
                        <MessageSquare className="h-4 w-4 mr-2" />
                        Mensajes
                      </Link>
                      <Link
                        to="/notificaciones"
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => setIsDropdownOpen(false)}
                      >
                        <Bell className="h-4 w-4 mr-2" />
                        Notificaciones
                      </Link>
                    </div>

                    {/* Configuración y Cuenta */}
                    <div className="py-1 border-b border-gray-100">
                      <Link
                        to="/perfil"
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => setIsDropdownOpen(false)}
                      >
                        <User className="h-4 w-4 mr-2" />
                        Mi Perfil
                      </Link>
                      <Link
                        to="/configuracion"
                        className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        onClick={() => setIsDropdownOpen(false)}
                      >
                        <Settings className="h-4 w-4 mr-2" />
                        Configuración
                      </Link>
                    </div>

                    {/* Cerrar Sesión */}
                    <div className="py-1">
                      <button
                        onClick={handleLogout}
                        className="flex items-center w-full px-4 py-2 text-sm text-red-600 hover:bg-gray-100"
                      >
                        <LogOut className="h-4 w-4 mr-2" />
                        Cerrar Sesión
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <Link to="/auth" className="text-gray-600 hover:text-gray-900">
                Iniciar Sesión
              </Link>
            )}
            

          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

/*
Gestión de Propiedades:

Mis Propiedades: Ver y gestionar propiedades publicadas
Publicar Propiedad: Crear nueva publicación


Actividad y Seguimiento:

Favoritos: Propiedades marcadas como favoritas
Propiedades Guardadas: Lista de propiedades para ver más tarde
Historial de Visitas: Registro de propiedades visitadas


Comunicaciones:

Mensajes: Chat con vendedores/compradores
Notificaciones: Alertas de actividad
Indicador de notificaciones no leídas


Información de Usuario:

Muestra el tipo de usuario (vendedor/comprador)
Estado de verificación para vendedores


Organización:

Menú organizado por categorías con separadores
Íconos intuitivos para cada opción
Diseño más espacioso y legible


Extras:

Botón de notificaciones rápidas en la barra
Contador de notificaciones
Hover states mejorados
*/
