// src/components/properties/MyProperties.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Eye, Trash } from 'lucide-react';
import { propertyService } from '../../services/api';
import { useAuth } from '../../context/AuthContext';

const MyProperties = () => {
 const { user } = useAuth();
 const [properties, setProperties] = useState([]);
 const [loading, setLoading] = useState(true);
 const [error, setError] = useState(null);

 useEffect(() => {
   loadProperties();
 }, []);

 const loadProperties = async () => {
   try {
     setLoading(true);
     const response = await propertyService.getMyProperties();
     if (response.status === 'success') {

       setProperties(response.data);
     }
   } catch (error) {
    //console.log("aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",error);
     setError(error.message);
   } finally {
     setLoading(false);
   }
 };

 const handleDelete = async (id) => {
   if (window.confirm('¿Estás seguro de eliminar esta propiedad?')) {
     try {
       await propertyService.deleteProperty(id);
       loadProperties(); // Recargar lista después de eliminar
     } catch (error) {
       setError('Error al eliminar la propiedad');
     }
   }
 };

 if (loading) {
   return <div className="text-center py-12">Cargando propiedades...</div>;
 }

 return (
   <div className="max-w-7xl mx-auto px-4 py-8">
     <div className="flex justify-between items-center mb-6">
       <h1 className="text-2xl font-bold">Mis Propiedades</h1>
       <Link
         to="/publicar"
         className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700"
       >
         Nueva Propiedad
       </Link>
     </div>

     {error && (
       <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
         {error}
       </div>
     )}

     {properties.length === 0 ? (
       <div className="text-center py-12 bg-gray-50 rounded-lg">
         <p className="text-gray-600">No tienes propiedades publicadas</p>
       </div>
     ) : (
       <div className="bg-white rounded-lg shadow overflow-hidden">
         <table className="min-w-full divide-y divide-gray-200">
           <thead className="bg-gray-50">
             <tr>
               <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                 Propiedad
               </th>
               <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                 Ubicación
               </th>
               <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                 Precio
               </th>
               <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                 Acciones
               </th>
             </tr>
           </thead>
           <tbody className="bg-white divide-y divide-gray-200">
             {properties.map((property) => (
               <tr key={property.id} className="hover:bg-gray-50">
                 <td className="px-6 py-4">
                   <div className="text-sm font-medium text-gray-900">
                     {property.titulo}
                   </div>
                 </td>
                 <td className="px-6 py-4">
                   <div className="text-sm text-gray-500">
                     {property.ubicacion}
                   </div>
                 </td>
                 <td className="px-6 py-4">
                   <div className="text-sm text-gray-900">
                     $ {property.precio?.toLocaleString('es-CL')}
                   </div>
                 </td>
                 <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                   <div className="flex space-x-3">
                     <Link
                       to={`/terreno/${property.id}`}
                       className="text-blue-600 hover:text-blue-900"
                       title="Ver propiedad"
                     >
                       <Eye size={20} />
                     </Link>
                     <button
                       onClick={() => handleDelete(property.id)}
                       className="text-red-600 hover:text-red-900"
                       title="Eliminar"
                     >
                       <Trash size={20} />
                     </button>
                   </div>
                 </td>
               </tr>
             ))}
           </tbody>
         </table>
       </div>
     )}
   </div>
 );
};

export default MyProperties;
