// src/context/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { authService } from '../services/api.js';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Cargar usuario desde localStorage al iniciar
    const userSession = localStorage.getItem('userSession');
    const token = localStorage.getItem('token');
    if (userSession && token) {
      setUser(JSON.parse(userSession));
    }
  }, []);

  const login = async (credentials) => {
    try {
      const response = await authService.login(credentials.email, credentials.password);
      if (response.status === 'success') {
        setUser(response.data.user);
        localStorage.setItem('userSession', JSON.stringify(response.data.user));
        localStorage.setItem('token', response.data.token);
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      throw error;
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('userSession');
    localStorage.removeItem('token');
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};