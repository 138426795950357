// src/components/terrenos/TerrenoDetalle.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ImageGallery from '../shared/ImageGallery';
import MapView from '../shared/MapView';
import MapLink from '../shared/MapLink';
import ContactModal from '../shared/ContactModal';
import { MapPin } from 'lucide-react';
import { propertyService } from '../../services/api.js';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;


const TerrenoDetalle = () => {
 const { id } = useParams();
 const [terreno, setTerreno] = useState(null);
 const [loading, setLoading] = useState(true);
 const [error, setError] = useState(null);
 const [isContactModalOpen, setIsContactModalOpen] = useState(false);

 useEffect(() => {
   cargarTerreno();
 }, [id]);

 const cargarTerreno = async () => {
   try {
     setLoading(true);
     const response = await propertyService.getProperty(id);
     if (response.status === 'success') {
       setTerreno(response.data);
     }
   } catch (error) {
     setError(error.message || 'Error al cargar la propiedad');
   } finally {
     setLoading(false);
   }
 };

  // Agregar esta función de ayuda para acceso seguro a propiedades
  const safeAccess = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };

  if (loading) return (
    <div className="max-w-7xl mx-auto px-4 py-8 flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
    </div>
  );

  if (error) return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="bg-red-50 p-4 rounded-lg text-center">
        <p className="text-red-600">{error}</p>
        <button 
          onClick={cargarTerreno}
          className="mt-4 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
        >
          Reintentar
        </button>
      </div>
    </div>
  );

  if (!terreno) return (
    <div className="max-w-7xl mx-auto px-4 py-8 text-center">
      <div className="bg-yellow-50 p-4 rounded-lg">
        <p className="text-yellow-700">Propiedad no encontrada</p>
      </div>
    </div>
  );

  // Extraer valores con acceso seguro
  
  //const imagenes = terreno.imagenes?.map(img => img.url) || [];
  const imagenes = terreno.imagenes?.map(img => `${BACKEND_URL}${img.url}`) || [];
  const tipoNombre = safeAccess(terreno, 'tipo.nombre') || terreno.tipo || 'No especificado';
  const servicios = terreno.servicios?.map(s => s.nombre || s)?.join(', ') || 'No especificados';
  const usuarioNombre = safeAccess(terreno, 'usuario.nombre') || 'No especificado';
  const usuarioTelefono = safeAccess(terreno, 'usuario.telefono') || '';
  const usuarioEmail = safeAccess(terreno, 'usuario.email') || '';

  return (
    <div className="max-w-7xl mx-auto px-4 py-8">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="p-6">
            <ImageGallery images={imagenes} />
          </div>
          <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">{terreno.titulo}</h1>
            <div className="flex items-center text-gray-600 mb-4">
              <MapPin size={20} className="mr-2" />
              {terreno.ubicacion}
            </div>
            {/* Precio formateado a chileno */}
            <p className="text-3xl font-bold text-gray-800 mb-6">
              {(() => {
                if (terreno.precio == null) return '$ Precio no disponible';
                const precio = Number(terreno.precio);
                if (!isNaN(precio)) {
                  return `$ ${precio.toLocaleString('es-CL')}`;
                } else {
                  return '$ Precio no disponible';
                }
              })()}
            </p>
            
            <div className="space-y-4">
              <div className="flex items-center justify-between border-b pb-2">
                <span className="text-gray-600">Superficie</span>
                <span className="font-semibold">{terreno.superficie} m²</span>
              </div>
              <div className="flex items-center justify-between border-b pb-2">
                <span className="text-gray-600">Tipo</span>
                <span className="font-semibold">{tipoNombre}</span>
              </div>
              <div className="flex items-center justify-between border-b pb-2">
                <span className="text-gray-600">Servicios</span>
                <span className="font-semibold">{servicios}</span>
              </div>
            </div>

            <div className="mt-8">
              <h2 className="text-xl font-bold mb-4">Descripción</h2>
              <p className="text-gray-600">{terreno.descripcion}</p>
            </div>

            <div className="mt-8">
              <button 
                onClick={() => setIsContactModalOpen(true)}
                className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 transition-colors flex items-center justify-center gap-2"
              >
                Contactar al vendedor
              </button>
            </div>
          </div>
        </div>

        {/* Sección del Mapa */}
        <div className="p-6 border-t">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-bold">Ubicación</h2>
            <MapLink 
              coordenadas={{ lat: terreno.lat, lng: terreno.lng }} 
              direccion={terreno.ubicacion} 
            />
          </div>
          <MapView 
            coordenadas={{ lat: terreno.lat, lng: terreno.lng }} 
            titulo={terreno.titulo} 
          />
          <p className="mt-4 text-gray-600">
            {terreno.ubicacion}
          </p>
        </div>
      </div>

      {/* Modal de Contacto */}
      <ContactModal 
        isOpen={isContactModalOpen}
        onClose={() => setIsContactModalOpen(false)}
        datosContacto={{
          nombreContacto: usuarioNombre,
          telefono: usuarioTelefono,
          whatsapp: usuarioTelefono,
          correo: usuarioEmail,
          horarioContacto: "Horario laborable",
          metodosContacto: ["telefono", "whatsapp", "correo"]
        }}
      />
    </div>
  );
};

export default TerrenoDetalle;
