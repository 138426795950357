// src/components/pages/AuthComponent.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Eye, EyeOff } from 'lucide-react';
import { useAuth } from '../../context/AuthContext';
import { authService } from '../../services/api.js'; // Agregamos esta línea

const AuthComponent = () => {
 const { login } = useAuth();
 const navigate = useNavigate();
 const [isLogin, setIsLogin] = useState(true);
 const [showPassword, setShowPassword] = useState(false);
 const [error, setError] = useState('');
 const [success, setSuccess] = useState('');
 const [isLoading, setIsLoading] = useState(false); // Agregamos estado de carga

 // Estados para el formulario de login
 const [loginData, setLoginData] = useState({
   email: '',
   password: ''
 });

 // Estados para el formulario de registro
 const [registerData, setRegisterData] = useState({
   nombre: '',
   email: '',
   telefono: '',
   password: '',
   confirmPassword: ''
 });

 const handleLogin = async (e) => {
   e.preventDefault();
   setError('');
   setSuccess('');

   try {
     await login(loginData);
     setSuccess('¡Inicio de sesión exitoso!');
     
     // Pequeño delay para mostrar el mensaje de éxito
     setTimeout(() => {
       navigate('/');
     }, 1000);
   } catch (error) {
     setError(error.message || 'Error al iniciar sesión');
   }
 };

 const handleRegister = async (e) => {
   e.preventDefault();
   setError('');
   setSuccess('');
   setIsLoading(true);

   // Validaciones locales
   if (registerData.password !== registerData.confirmPassword) {
     setError('Las contraseñas no coinciden');
     setIsLoading(false);
     return;
   }

   if (registerData.password.length < 6) {
     setError('La contraseña debe tener al menos 6 caracteres');
     setIsLoading(false);
     return;
   }

   try {
     const response = await authService.register({
       email: registerData.email,
       password: registerData.password,
       nombre: registerData.nombre,
       telefono: registerData.telefono
     });

     if (response.status === 'success') {
       setSuccess('¡Registro exitoso! Ya puedes iniciar sesión');
       
       // Limpiar el formulario
       setRegisterData({
         nombre: '',
         email: '',
         telefono: '',
         password: '',
         confirmPassword: ''
       });
       
       // Cambiar a la vista de login después de un breve delay
       setTimeout(() => {
         setIsLogin(true);
       }, 1500);
     }
   } catch (error) {
     setError(error.message || 'Error al registrar usuario');
   } finally {
     setIsLoading(false);
   }
 };

 return (
   <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
     <div className="max-w-md w-full space-y-8">
       <div>
         <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
           {isLogin ? 'Iniciar Sesión' : 'Crear Cuenta'}
         </h2>
       </div>

       {/* Mensajes de error y éxito */}
       {error && (
         <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
           {error}
         </div>
       )}
       {success && (
         <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative">
           {success}
         </div>
       )}

       {isLogin ? (
         // Formulario de Login
         <form className="mt-8 space-y-6" onSubmit={handleLogin}>
           <div className="rounded-md shadow-sm -space-y-px">
             <div>
               <label htmlFor="login-email" className="sr-only">Email</label>
               <input
                 id="login-email"
                 name="email"
                 type="email"
                 required
                 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                 placeholder="Email"
                 value={loginData.email}
                 onChange={(e) => setLoginData({...loginData, email: e.target.value})}
               />
             </div>
             <div className="relative">
               <label htmlFor="login-password" className="sr-only">Contraseña</label>
               <input
                 id="login-password"
                 name="password"
                 type={showPassword ? "text" : "password"}
                 required
                 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                 placeholder="Contraseña"
                 value={loginData.password}
                 onChange={(e) => setLoginData({...loginData, password: e.target.value})}
               />
               <button
                 type="button"
                 className="absolute inset-y-0 right-0 pr-3 flex items-center"
                 onClick={() => setShowPassword(!showPassword)}
               >
                 {showPassword ? <EyeOff className="h-5 w-5 text-gray-400" /> : <Eye className="h-5 w-5 text-gray-400" />}
               </button>
             </div>
           </div>

           <div>
             <button
               type="submit"
               className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
             >
               Iniciar Sesión
             </button>
           </div>
         </form>
       ) : (
         // Formulario de Registro
         <form className="mt-8 space-y-6" onSubmit={handleRegister}>
           <div className="rounded-md shadow-sm -space-y-px">
             <div>
               <label htmlFor="register-nombre" className="sr-only">Nombre completo</label>
               <input
                 id="register-nombre"
                 name="nombre"
                 type="text"
                 required
                 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                 placeholder="Nombre completo"
                 value={registerData.nombre}
                 onChange={(e) => setRegisterData({...registerData, nombre: e.target.value})}
               />
             </div>
             <div>
               <label htmlFor="register-email" className="sr-only">Email</label>
               <input
                 id="register-email"
                 name="email"
                 type="email"
                 required
                 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                 placeholder="Email"
                 value={registerData.email}
                 onChange={(e) => setRegisterData({...registerData, email: e.target.value})}
               />
             </div>
             <div>
               <label htmlFor="register-telefono" className="sr-only">Teléfono</label>
               <input
                 id="register-telefono"
                 name="telefono"
                 type="tel"
                 required
                 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                 placeholder="Teléfono"
                 value={registerData.telefono}
                 onChange={(e) => setRegisterData({...registerData, telefono: e.target.value})}
               />
             </div>
             <div>
               <label htmlFor="register-password" className="sr-only">Contraseña</label>
               <input
                 id="register-password"
                 name="password"
                 type={showPassword ? "text" : "password"}
                 required
                 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                 placeholder="Contraseña"
                 value={registerData.password}
                 onChange={(e) => setRegisterData({...registerData, password: e.target.value})}
               />
             </div>
             <div className="relative">
               <label htmlFor="register-confirm-password" className="sr-only">Confirmar contraseña</label>
               <input
                 id="register-confirm-password"
                 name="confirmPassword"
                 type={showPassword ? "text" : "password"}
                 required
                 className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                 placeholder="Confirmar contraseña"
                 value={registerData.confirmPassword}
                 onChange={(e) => setRegisterData({...registerData, confirmPassword: e.target.value})}
               />
               <button
                 type="button"
                 className="absolute inset-y-0 right-0 pr-3 flex items-center"
                 onClick={() => setShowPassword(!showPassword)}
               >
                 {showPassword ? <EyeOff className="h-5 w-5 text-gray-400" /> : <Eye className="h-5 w-5 text-gray-400" />}
               </button>
             </div>
           </div>

           <div>
 <button
   type="submit"
   disabled={isLoading}
   className={`group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white ${
     isLoading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'
   } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`}
 >
   {isLoading ? 'Registrando...' : 'Registrarse'}
 </button>
           </div>
         </form>
       )}

       <div className="text-center">
         <button
           onClick={() => {
             setIsLogin(!isLogin);
             setError('');
             setSuccess('');
           }}
           className="text-sm text-blue-600 hover:text-blue-500"
         >
           {isLogin ? '¿No tienes cuenta? Regístrate' : '¿Ya tienes cuenta? Inicia sesión'}
         </button>
       </div>
     </div>
   </div>
 );
};

export default AuthComponent;
