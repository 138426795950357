// src/components/properties/CreateProperty.js  (archivo nuevo)
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { propertyService } from '../../services/api';
import { MapPin, Upload, X } from 'lucide-react';
import MapView from '../shared/MapView';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const CreateProperty = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [types, setTypes] = useState([]);
  const [services, setServices] = useState([]);

  // Estados del formulario
  const [formData, setFormData] = useState({
    titulo: '',
    descripcion: '',
    precio: '',
    superficie: '',
    ubicacion: '',
    type_id: '',
    lat: null,
    lng: null,
    servicios: [],
    imagenes: []
  });

  // Cargar datos iniciales
  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const [typesResponse, servicesResponse] = await Promise.all([
          propertyService.getPropertyTypes(),
          propertyService.getServices()
        ]);
        setTypes(typesResponse.data);
        setServices(servicesResponse.data);
      } catch (error) {
        setError('Error al cargar datos iniciales');
        console.error('Error:', error);
      }
    };

    loadInitialData();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      await propertyService.create(formData);
      navigate('/mis-propiedades');
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'number' ? Number(value) : value
    }));
  };

  const handleServiceToggle = (serviceId) => {
    setFormData(prev => ({
      ...prev,
      servicios: prev.servicios.includes(serviceId)
        ? prev.servicios.filter(id => id !== serviceId)
        : [...prev.servicios, serviceId]
    }));
  };

  const handleImageAdd = (url) => {
    setFormData(prev => ({
      ...prev,
      imagenes: [...prev.imagenes, url]
    }));
  };

  const handleImageRemove = (index) => {
    setFormData(prev => ({
      ...prev,
      imagenes: prev.imagenes.filter((_, i) => i !== index)
    }));
  };

  if (!user) {
    return (
      <div className="text-center py-12">
        <p>Debes iniciar sesión para publicar una propiedad</p>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6">Publicar Nueva Propiedad</h1>

      {error && (
        <div className="bg-red-50 text-red-600 p-4 rounded-lg mb-6">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Información básica */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Información Básica</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Título
              </label>
              <input
                type="text"
                name="titulo"
                value={formData.titulo}
                onChange={handleInputChange}
                required
                className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Tipo de Propiedad
              </label>
              <select
                name="type_id"
                value={formData.type_id}
                onChange={handleInputChange}
                required
                className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
              >
                <option value="">Selecciona un tipo</option>
                {types.map(type => (
                  <option key={type.id} value={type.id}>
                    {type.nombre}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="mt-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Descripción
            </label>
            <textarea
              name="descripcion"
              value={formData.descripcion}
              onChange={handleInputChange}
              required
              rows={4}
              className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>

        {/* Precios y medidas */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Precios y Medidas</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Precio (CLP)
              </label>
              <input
                type="number"
                name="precio"
                value={formData.precio}
                onChange={handleInputChange}
                required
                min="0"
                className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Superficie (m²)
              </label>
              <input
                type="number"
                name="superficie"
                value={formData.superficie}
                onChange={handleInputChange}
                required
                min="0"
                className="w-full p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
        </div>

        {/* Ubicación */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Ubicación</h2>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Dirección
            </label>
            <div className="flex gap-2">
              <input
                type="text"
                name="ubicacion"
                value={formData.ubicacion}
                onChange={handleInputChange}
                required
                className="flex-1 p-2 border rounded focus:ring-blue-500 focus:border-blue-500"
                placeholder="Ingresa la dirección de la propiedad"
              />
            </div>
          </div>
          {/* Mapa */}
          <div className="h-64">
            <MapView
              coordenadas={formData.lat ? { lat: formData.lat, lng: formData.lng } : null}
              editable={true}
              onLocationSelect={({ lat, lng }) => {
                setFormData(prev => ({
                  ...prev,
                  lat,
                  lng
                }));
              }}
            />
          </div>
        </div>

        {/* Servicios */}
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-4">Servicios Disponibles</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
            {services.map(service => (
              <label
                key={service.id}
                className="flex items-center space-x-2 cursor-pointer"
              >
                <input
                  type="checkbox"
                  checked={formData.servicios.includes(service.id)}
                  onChange={() => handleServiceToggle(service.id)}
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <span>{service.nombre}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Imágenes */}
        <div className="bg-white p-6 rounded-lg shadow">
        <h2 className="text-xl font-semibold mb-4">Imágenes</h2>
        <div className="space-y-4">
          <div className="flex items-center justify-center w-full">
            <label className="flex flex-col items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <Upload className="w-8 h-8 mb-2 text-gray-500" />
                <p className="mb-2 text-sm text-gray-500">
                  <span className="font-semibold">Click para seleccionar</span> o arrastra y suelta
                </p>
                <p className="text-xs text-gray-500">PNG, JPG o WEBP (MAX. 5MB)</p>
              </div>
              <input 
                type="file" 
                className="hidden" 
                multiple 
                accept="image/*"
                onChange={async (e) => {
                  try {
                    const formData = new FormData();
                    Array.from(e.target.files).forEach((file) => {
                      formData.append('images', file);
                    });
                    
                    const response = await propertyService.uploadImages(formData);
                    setFormData(prev => ({
                      ...prev,
                      imagenes: [...prev.imagenes, ...response.data.map(img => img.url)]
                    }));
                  } catch (error) {
                    setError('Error al subir imágenes');
                  }
                }}
              />
            </label>
          </div>
          
          {/* Preview de imágenes */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            {formData.imagenes.map((url, index) => (
              <div key={index} className="relative group">
                <img
                  src={`${BACKEND_URL}${url}`}
                  alt={`Imagen ${index + 1}`}
                  className="w-full h-24 object-cover rounded"
                />
                <button
                  type="button"
                  onClick={() => handleImageRemove(index)}
                  className="absolute top-1 right-1 p-1 bg-red-500 text-white rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                >
                  <X size={16} />
                </button>
              </div>
            ))}
          </div>
        </div>
        </div>

        {/* Botón de envío */}
        <button
          type="submit"
          disabled={loading}
          className={`w-full py-3 rounded-lg text-white font-medium
            ${loading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'}
          `}
        >
          {loading ? 'Publicando...' : 'Publicar Propiedad'}
        </button>
      </form>
    </div>
  );
};

export default CreateProperty;