// src/services/api.js
const API_URL = process.env.REACT_APP_API_URL;


export const authService = {
  login: async (email, password) => {
    const response = await fetch(`${API_URL}/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, password })
    });
    const data = await response.json();
    if (!response.ok) throw new Error(data.message || 'Error al iniciar sesión');
    return data;
  },

  register: async (userData) => {
    const response = await fetch(`${API_URL}/auth/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userData)
    });
    const data = await response.json();
    if (!response.ok) throw new Error(data.message || 'Error en el registro');
    return data;
  },

  verifyToken: async (token) => {
    const response = await fetch(`${API_URL}/auth/verify`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.json();
  }
};

export const propertyService = {
  getProperties: async (filters = {}) => {
    const params = new URLSearchParams();
    
    if (filters.precioMin) params.append('precioMin', filters.precioMin);
    if (filters.precioMax) params.append('precioMax', filters.precioMax);
    if (filters.ubicacion) params.append('ubicacion', filters.ubicacion);
    if (filters.tipo) params.append('tipo', filters.tipo);
    if (filters.superficieMin) params.append('superficieMin', filters.superficieMin);
    if (filters.superficieMax) params.append('superficieMax', filters.superficieMax);
    if (filters.servicios?.length) {
      filters.servicios.forEach(servicio => 
        params.append('servicios', servicio)
      );
    }
    if (filters.destacado) params.append('destacado', true);

    const response = await fetch(`${API_URL}/properties?${params}`);
    const data = await response.json();
    if (!response.ok) throw new Error(data.message || 'Error al obtener propiedades');
    return data;
  },

  getProperty: async (id) => {
    const response = await fetch(`${API_URL}/properties/${id}`);
    const data = await response.json();
    if (!response.ok) throw new Error(data.message || 'Error al obtener la propiedad');
    return data;
  },

  create: async (propertyData) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/properties`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(propertyData)
    });
    const data = await response.json();
    if (!response.ok) throw new Error(data.message || 'Error al crear la propiedad');
    return data;
  },

  getPropertyTypes: async () => {
    const response = await fetch(`${API_URL}/properties/types`);
    const data = await response.json();
    if (!response.ok) throw new Error(data.message || 'Error al obtener tipos de propiedad');
    return data;
  },

  getServices: async () => {
    const response = await fetch(`${API_URL}/services`);
    const data = await response.json();
    if (!response.ok) throw new Error(data.message || 'Error al obtener servicios');
    return data;
  },

  getMyProperties: async () => {
    const token = localStorage.getItem('token');
    console.log("bbbbbbbbbbbbbbbbbbbbbbbbb");
    const response = await fetch(`${API_URL}/properties/my/properties`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const data = await response.json();
    if (!response.ok) throw new Error(data.message || 'Error al obtener propiedades ss');
    //console.log(data.message);
    return data;
  },

  updatePropertyStatus: async (id, status) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/properties/${id}/status`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ status })
    });
    const data = await response.json();
    if (!response.ok) throw new Error(data.message || 'Error al actualizar estado');
    return data;

  },
  // Agregar después de updatePropertyStatus
  deleteProperty: async (id) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/properties/${id}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    const data = await response.json();
    if (!response.ok) throw new Error(data.message || 'Error al eliminar la propiedad');
    return data;
  },

  uploadImages: async (formData) => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_URL}/upload`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: formData
    });
    const data = await response.json();
    if (!response.ok) throw new Error(data.message || 'Error al subir imágenes');
    return data;
   }

};
