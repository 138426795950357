// src/components/terrenos/TerrenosList.js
import React, { useState, useEffect } from 'react';
import { Star } from 'lucide-react';
import Filtros from '../shared/Filtros';
import TerrenoCard from './TerrenoCard';
import DestacadosCarousel from '../shared/DestacadosCarousel';
import { propertyService } from '../../services/api.js';

const TerrenosList = () => {
 const [loading, setLoading] = useState(true);
 const [error, setError] = useState(null);
 const [propiedades, setPropiedades] = useState([]);
 const [filtros, setFiltros] = useState(() => {
   const savedFilters = localStorage.getItem('filtrosActivos');
   return savedFilters ? JSON.parse(savedFilters) : {
     precioMin: '',
     precioMax: '',
     ubicacion: '',
     tipo: '',
     superficieMin: '',
     superficieMax: '',
     servicios: [],
     mostrarSoloDestacados: false
   };
 });

 useEffect(() => {
   localStorage.setItem('filtrosActivos', JSON.stringify(filtros));
   cargarPropiedades();
 }, [filtros]);

 const cargarPropiedades = async () => {
   try {
     setLoading(true);
     const response = await propertyService.getProperties(filtros);
     if (response.status === 'success') {
       setPropiedades(response.data);
     }
   } catch (error) {
     setError(error.message);
     console.error('Error al cargar propiedades:', error);
   } finally {
     setLoading(false);
   }
 };

 // Separar destacados para el carrusel
 const terrenosDestacados = propiedades.filter(t => t.destacado);

 return (
   <div className="max-w-7xl mx-auto px-4 py-8">
     <Filtros 
       filtros={filtros} 
       setFiltros={setFiltros}
       resultCount={propiedades.length}
       totalCount={propiedades.length}
     />
     
     {loading && (
       <div className="text-center py-12">
         <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mx-auto"></div>
         <p className="mt-4 text-gray-600">Cargando propiedades...</p>
       </div>
     )}

     {error && (
       <div className="text-center py-12 bg-red-50 rounded-lg">
         <p className="text-red-600">{error}</p>
         <button 
           onClick={cargarPropiedades}
           className="mt-4 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
         >
           Reintentar
         </button>
       </div>
     )}

     {!loading && !error && (
       <>
         {/* Sección de destacados con carrusel */}
         {!filtros.mostrarSoloDestacados && terrenosDestacados.length > 0 && (
           <div className="mt-8 mb-12">
             <h2 className="text-2xl font-bold mb-6 flex items-center">
               <Star className="text-yellow-400 mr-2" size={24} fill="currentColor" />
               Propiedades Destacadas
             </h2>
             <DestacadosCarousel terrenos={terrenosDestacados} />
           </div>
         )}

         {/* Todas las propiedades */}
         <div>
           <h2 className="text-2xl font-bold mb-6">Todas las Propiedades</h2>
           <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
             {propiedades.map((terreno) => (
               <TerrenoCard key={terreno.id} terreno={terreno} />
             ))}
           </div>
         </div>

         {propiedades.length === 0 && (
           <div className="text-center py-12">
             <p className="text-gray-500 text-lg mb-4">
               No se encontraron propiedades con los filtros seleccionados
             </p>
             <button
               onClick={() => setFiltros({
                 ...filtros,
                 mostrarSoloDestacados: false
               })}
               className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors"
             >
               Mostrar todas las propiedades
             </button>
           </div>
         )}

         {propiedades.length > 0 && (
           <div className="mt-4 text-center text-gray-500">
             Mostrando {propiedades.length} propiedades
           </div>
         )}
       </>
     )}
   </div>
 );
};

export default TerrenosList;