// src/components/shared/MapView.js
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Arreglo para el ícono del marcador
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
 iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
 iconUrl: require('leaflet/dist/images/marker-icon.png'),
 shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// Componente para manejar eventos del mapa
const LocationMarker = ({ position, setPosition, editable }) => {
 useMapEvents({
   click(e) {
     if (editable) {
       setPosition([e.latlng.lat, e.latlng.lng]);
     }
   },
 });

 return position ? (
   <Marker position={position}>
     <Popup>Ubicación seleccionada</Popup>
   </Marker>
 ) : null;
};

const MapView = ({ coordenadas, titulo, editable = false, onLocationSelect = null }) => {
 const [position, setPosition] = useState(
   coordenadas ? [coordenadas.lat, coordenadas.lng] : [-33.4489, -70.6693] // Santiago por defecto
 );

 useEffect(() => {
   if (coordenadas) {
     setPosition([coordenadas.lat, coordenadas.lng]);
   }
 }, [coordenadas]);

 const handlePositionChange = (newPosition) => {
   setPosition(newPosition);
   if (onLocationSelect) {
     onLocationSelect({ lat: newPosition[0], lng: newPosition[1] });
   }
 };

 useEffect(() => {
   const adjustMapZIndex = () => {
     const mapContainer = document.querySelector('.leaflet-container');
     const mapPanes = document.querySelectorAll('.leaflet-pane');
     const mapControls = document.querySelectorAll('.leaflet-control');
     const mapOverlays = document.querySelectorAll('.leaflet-overlay-pane');
     
     if (mapContainer) mapContainer.style.zIndex = '10';
     mapPanes.forEach(pane => pane.style.zIndex = '10');
     mapControls.forEach(control => control.style.zIndex = '10');
     mapOverlays.forEach(overlay => overlay.style.zIndex = '10');
   };

   adjustMapZIndex();
   const observer = new MutationObserver(adjustMapZIndex);
   observer.observe(document.body, { childList: true, subtree: true });

   return () => observer.disconnect();
 }, []);

 return (
   <div className="h-full w-full rounded-lg overflow-hidden shadow-lg relative" style={{ zIndex: 10 }}>
     <MapContainer 
       center={position} 
       zoom={13} 
       style={{ height: '100%', width: '100%' }}
       zIndex={10}
       className="relative z-10"
     >
       <TileLayer
         attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
         url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
         zIndex={10}
       />
       <LocationMarker 
         position={position} 
         setPosition={handlePositionChange}
         editable={editable}
       />
     </MapContainer>
     {editable && (
       <div className="absolute bottom-2 left-2 right-2 bg-white p-2 rounded shadow text-sm text-gray-600 text-center">
         Haz clic en el mapa para seleccionar la ubicación
       </div>
     )}
   </div>
 );
};

export default MapView;
